import './App.css';
import { Routes, Route, BrowserRouter } from "react-router-dom";
// import Register from "./pages/Register";
import Main from "./pages/Main";
import RSVP from "./pages/Rsvp";

function App() {
  return (
    <Routes>
      {/* Main */}
      <Route path="/" element={<Main />} />
      {/* <Register /> */}
      <Route path="/rsvp" element={<RSVP />} />
    </Routes>
  );
}

export default App;
