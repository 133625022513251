import React, {useEffect} from "react";
import './Common.css';
import './Main.css';

// import VerifyCode from "./VerifyCode";

// assets
// import Video_raw from "../media/2_raw.mp4";
// import Video_mp4 from "../media/2_1080p_25f_cq39.mp4";
// import Video_mp4 from "../media/2_1080p_25f_cq39.mp4";
// import Video_webm from "../media/2.webm";
// import VideoPoster from "../media/poster.jpg";
import ImagePoster from "../media/lime4_web.jpg";
import ImagePosterDesc from "../media/lime4_web_outlined.svg";
// import WeWillBack from "../media/lime2_web.svg";
// import Video_mp4 from "../media/lime3_web.mp4";
// import Video_webm from "../media/lime3_web.webm";
import Sponsor_Licksip_Logo from "../media/partner_licksip_official.svg";
import instagram_Logo from "../media/2.logo_instagram.svg";
import Youtube_Logo from "../media/3.logo_youtube.svg";


export default function Main() {
  // useEffect(() => {
  //     document.title = "limestudio.live";
  // }, []);
  
  return (
    <div className="contents">
      
      {/* <h1>Main</h1> */}
      <div className="video">
        <img src={ImagePoster} className="bg-video" alt="poster" />
        <img src={ImagePosterDesc} className="bg-video" alt="poster" />
        {/* <video 
        className="bg-video"
        playsInline 
        loop 
        muted 
        // autoplay 
        autotart="true"
        autoPlay 
        disableRemotePlayback
        // type="video/mp4"
        poster={VideoPoster}
        >
          <source type="video/mp4" src={Video_mp4} />
          <source type="video/webm" src={Video_webm} />
          
  
        </video> */}
      </div>
      <div className="bottom-area">
        {/* 인증폼 */}
        <div className="verify-code-form">
          {/* <img src={WeWillBack} alt="weWillBack" /> */}
          {/* <form>
            <input type="text" placeholder="코드 입력 / Insert code" />
            <input type="submit" value="확인 / Submit" />
            <Link to="/rsvp">Products</Link>
          </form>*/}
          {/* <VerifyCode />  */}
          <hr />
        </div>
          
        {/* 스폰서 및 푸터 */}
        <div className="sponsor-footer">

          <div className="sponsor">
            {/* 이미지왼쪽정렬 */}
            {/* <h2>suncollaboration@gmail.com</h2> */}
            {/* <h2>Partner</h2> */}
            <a href="https://www.instagram.com/licksip_official" target="_blank" rel="noopener noreferrer">
              <img src={Sponsor_Licksip_Logo} alt="sponsor" />
            </a>
          </div>

          <div className="footer">

          {/* instagram */}
          <a href="https://www.instagram.com/sun.collaboration/" target="_blank" rel="noopener noreferrer">
            <img src={instagram_Logo} alt="instagram" />
          </a>
          <a href="https://www.youtube.com/@sun.collaboration" target="_blank" rel="noopener noreferrer">
            <img src={Youtube_Logo} alt="youtube" />
          </a>
          </div>

        </div>
      </div>

    </div>
  );
}
