import React, { useState }  from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FerrisWheelSpinnerOverlay } from 'react-spinner-overlay'

// import './VerifyCode.css';
import SweetAlert2 from 'react-sweetalert2';
import axios from "axios";


export default function RsvpForm() { 
  let [loading, setLoading] = useState(false);
  const [swalProps, setSwalProps] = useState({});
  const [codeProps, setCodeProps] = useState({});
  const [registerProps, setRegisterProps] = useState({});
  const location = useLocation();
  const codeState = { ...location.state };
  const navigate = useNavigate();

  const makeRegister = (event) => {
    // setImageSrc("/images/Contents/Icons/circle-check-solid.png")
    event.preventDefault(); //해당 문구를 추가해야 새로고침을 방지할 수 있다.

    console.log(event.target);
    if(!event.target.name.value || !event.target.email.value || !event.target.tel.value){
      // alert("코드를 입력해주세요.\nPlease insert invitation code.");
      setSwalProps({
        show: true,
        icon: "warning",
        // title: 'Example',
        html: '내용을 입력해주세요.<br>plase fill all forms.',
        customClass: {
          popup: 'alert-popup',
          confirmButton: 'alert-popup-confirm-button',
        },
        backdrop: true,
        background: '#000',
        color: '#fff',
      }); 
      return;
    }

    setLoading(true);
    // request PUT /registration
    // const code = event.target.code.value;
    axios.put(`${process.env.REACT_APP_API_URL}/registration/`, {
      registration_item_code: event.target.code.value,
      registration_name: event.target.name.value,
      registration_mail: event.target.email.value,
      registration_call: event.target.tel.value,
    }).then((response) => {
      const result = response.data
 
      if(response.status !== 200){
        // server error
        alert("서버 오류가 발생하였습니다.\nServer error has occurred.\nevent@limestudio.live로 문의해주세요(please contact us)");
      }
      if(result.registration_id){
        setLoading(false);
        console.log("registered");
        setSwalProps({
          show: true,
          icon: "success",
          // title: 'Example',
          html: '등록되었습니다<br>메일을 확인해주세요.<br>You have been registered<br>Please check your email.',
          customClass: {
            popup: 'alert-popup',
            confirmButton: 'alert-popup-confirm-button',
          },
          backdrop: true,
          background: '#000',
          color: '#fff',
        }); 
        setRegisterProps({
          registration: true,
          registration_id: result.registration_id,
          registration_name: result.registration_name,
          registration_mail: result.registration_mail,
          registration_call: result.registration_call,
          registration_item_code: result.registration_item_code,
          registration_date: result.registration_date,
        });
        // navigate("/");
      }else{
        // alert("등록에 실패하였습니다.\nFailed to register.");
        setSwalProps({
          show: true,
          icon: "error",
          // title: 'Example',
          html: '등록에 실패하였습니다.<br>Failed to register. <br>event@limestudio.live로 문의해주세요(please contact us)',
          customClass: {
            popup: 'alert-popup',
            confirmButton: 'alert-popup-confirm-button',
          },
          backdrop: true,
          background: '#000',
          color: '#fff',
        }); 
        return;
      }
    });
    // console.log(event.target.code.value);
  };

  return (
    <form onSubmit={makeRegister}>
    {/* phone Number */}
    <div className="register-form-subject register-fields">
      <div className="register-form-title">
        <div className="title"> 
        연락처
        </div>
        <div className="label"> 
        Cell Phone
        </div>
      </div>
      <div className="register-form-value">
        <input type="tel" name="tel"/>
      </div>
    </div>
    
    {/* email */}
    <div className="register-form-subject register-fields">
      <div className="register-form-title">
        <div className="title"> 
        이메일
        </div>
        <div className="label"> 
        E-mail
        </div>
      </div>
      <div className="register-form-value">
        <input type="email" name="email" />
      </div>
    </div>
      
    {/* name */}
    <div className="register-form-subject register-fields no-margin-bottom">
      <div className="register-form-title">
        <div className="title"> 
        이름
        </div>
        <div className="label"> 
        Name
        </div>
      </div>
      <div className="register-form-value">
        <input type="text" name="name" maxLength={5}/>
      </div>
    </div>
    <div className="label name-info">
      * 다수일 경우, 한 명당 하나의 폼을 작성해주세요<br />
      * One registration per person
    </div>
    
    <input type="hidden" name='code' value={codeState.code} />

    {/* disclaimer */}
    <div className="disclaimer">
      {/* subject 1 */}
      <div className="register-form-subject">
        <div className="register-form-title">
          <div className="title"> 
          1.
          </div>
        </div>
        <div className="register-form-value disclaimer-text white-text">
          limestudio.live가 진행되는 동안 현장 스케치를 위해 사진 및 
          영상 촬영이 있을 수 있으며, 기록컨텐츠로 SNS 또는 웹사이트에 
          업로드 될 수 있습니다.
          <div className="label disclaimer-text">
          During limestudio.live, photo and video documentation of the 
          event will take place and may be shared on social media channels.
          </div>
        </div>
      </div>
      {/* subject 2 */}
      <div className="register-form-subject">
        <div className="register-form-title">
          <div className="title"> 
          2.
          </div>
        </div>
        <div className="register-form-value disclaimer-text white-text">
          개인 정보를 다음의 목적으로 수집 및 이용합니다.
          {/* excaptional bottom margin */}
          <div className="label disclaimer-text white-text" style={{marginBottom: "0.4em"}}>
          &emsp;&emsp;- 이름 및 연락처 <br />
          &emsp;&emsp;- 이용목적: limestudio.live 참가자 관리 및 안내  <br />
          &emsp;&emsp;- 이용 및 보유기간: 행사 이후 즉시 폐기합니다.  <br />
          </div>
          귀하가 이용 중단의사를 밝힐 경우 파기함.
          개인정보 수집·보관·이용에 관한 결정권은 귀하에게 있습니다.
          관련문의는 event@limestudio.live로 연락주세요.<br />
          <div className="label disclaimer-text">
          We collect your personal information for the purpose of the event. 
          Your information will be deleted immediately after the event. 
          Any inquiries to event@limestudio.live
          </div>
        </div>
      </div>
    </div>
    <input type="submit" className="agree-submit font500" value="전체 동의 및 제출&#10;Agree and Submit" />
    <FerrisWheelSpinnerOverlay
      　　loading={loading} 
       overlayColor="rgba(0,0,0, 0.6)"
       color="#fff"
        size={30} />
    <SweetAlert2 {...swalProps}
          onResolve={result => {
            setSwalProps  ({ show: false });
            if(registerProps.registration){
              navigate("/");
            }
          }}
      />
  </form>
    
  );
}

