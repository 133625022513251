import React, { useState, useEffect }  from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import RsvpForm from "./RsvpForm";

import './Common.css';
import './Rsvp.css';

// assets
import Header_Logo from '../media/4.header.svg';
import Value_Date from '../media/5.date.svg';
import Value_Location from '../media/6.location.svg';

// import VideoPoster from "../media/poster.jpg";
// import Video_mp4 from "../media/KakaoTalk_Video_2024-02-24-15-43-14.mp4";
// import Video_webm from "../media/s2_1080p_25f_cq48.webm";

// end of assets

export default function Rsvp() {
  const location = useLocation();
  const navigate = useNavigate();
  const codeState = { ...location.state };

  useEffect(() => {
      if(codeState.code === undefined || codeState.code === null){
        navigate("/");
      }
   });

  return (
    // css body background color override

    
    <div className="contents bg-b4b4b4">
      <style>{'body { background-color: #B4B4B4; }'}</style>

      {/* <video 
        className="bg-video-register"
        playsInline 
        loop 
        muted 
        // autoplay 
        autotart="true"
        autoPlay 
        disableRemotePlayback
        // type="video/mp4"
        poster={VideoPoster}
        > */}
          {/* <source type="video/webm" src={Video_webm} /> */}
          {/* <source type="video/mp4" src={Video_mp4} />
        </video> */}

      <div className="register-form">
        <div className="register-form-logo">
          <img src={Header_Logo} alt="header" />
        </div>

        {/* date */}
        <div className="register-form-subject">
          <div className="register-form-title">
            <div className="title"> 
            일시
            </div>
            <div className="label"> 
            Date
            </div>
          </div>
          <div className="register-form-value">
            <img src={Value_Date} alt="header" />
            <div className="label">* Doors open at 13:00 / closes at 19:20</div>
          </div>
        </div>

        {/* location */}
        <div className="register-form-subject">
          <div className="register-form-title">
            <div className="title"> 
            장소
            </div>
            <div className="label"> 
            Location
            </div>
          </div>
          <div className="register-form-value">
            <img src={Value_Location} alt="header" />
            <div className="label">
            * 서울시 서초구 명달로 28길 29 지하 1층
            <br/>
            * 주차는 불가능합니다.
            </div>
          </div>
        </div>

        <hr className="no-color"/>
        {/* input fields */}
        <RsvpForm />
        {/* <h1>{codeState.code}</h1> */}
      </div>
    </div>
  );
}
